@import '~antd/dist/antd.css';

g[aria-labelledby] {
    display: none !important;
}

.ant-menu .ant-avatar{
    margin: 0 1em;
}

.ant-layout-header{
    padding: 0;
}

.ant-layout-content{
    padding: 50px 50px 0;
}

.table-ghost-wrapper .ant-btn{
    margin-right: 8px;
    margin-bottom: 12px;
}

@media (max-width: 671px) {
    /* Temporary CSS for : Component/Topbar */
    .ant-menu-item .anticon{
        margin: 0;
    }

    .ant-layout-content{
        padding: 0;
    }
    
    .ant-menu-item .nav-word{
        display: none;
    }

    .ant-layout-sider .ant-menu-item .anticon{
        margin-right: 5px;
    }
}